import { MatDatepickerIntl } from "@angular/material/datepicker";
import { Injectable } from "@angular/core";

@Injectable()
export class PtBrMatDatepickerIntl extends MatDatepickerIntl {
  constructor() {
    super();

    this.calendarLabel = "Calendário";
    this.openCalendarLabel = "Abrir calendário";
    this.prevMonthLabel = "Mês anterior";
    this.nextMonthLabel = "Próximo mês";
    this.prevYearLabel = "Ano anterior";
    this.nextYearLabel = "Próximo ano";
    this.switchToMonthViewLabel = "Mudar para visão mensal";
  }

  months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  days = ["D", "S", "T", "Q", "Q", "S", "S"];
  rangeLabel = "De: ${startDate} até ${endDate}";
}
