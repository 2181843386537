import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Início',
  },
  {
    displayName: 'Dashboards',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    navCap: 'Menu',
  },
  //Cadastro
  {
    displayName: 'Cadastro',
    iconName: 'archive',
    children: [
      // Dados Cliente
      {
        displayName: 'Dados Cliente',
        iconName: 'menu',
        children: [
          // Cliente
          {
            displayName: 'Cliente',
            route: '/cliente',
            modulo: 'cliente',
          },
        ],
      },
      // Dados Fazenda
      {
        displayName: 'Dados Fazendas',
        iconName: 'menu',
        children: [
          {
            displayName: 'Regionais / Fazendas',
            route: '/regional-fazenda',
            modulo: 'regional',
          },
        ],
      },
      // Dados Funcionario
      {
        displayName: 'Dados Funcionários',
        iconName: 'users-group',
        children: [
          // Funcionário Fixo
          {
            displayName: 'Fixo',
            route: '/funcionario-fixo',
            modulo: 'funcionario',
          },
          // Funcionário Administrativo
          {
            displayName: 'Administrativo',
            route: '/funcionario-administrativo',
            modulo: 'funcionario',
          },
          // Funcionário Encarregado
          {
            displayName: 'Encarregado',
            route: '/funcionario-encarregado',
            modulo: 'funcionario',
          },
          // Funcionário Colhedor
          {
            displayName: 'Colhedor',
            route: '/colhedor',
            modulo: 'colheita',
          },
        ],
      },
      // Dados colheita
      {
        displayName: 'Dados Colheita',
        iconName: 'seeding',
        children: [
          {
            displayName: 'Turma',
            route: '/turma',
            modulo: 'colheita',
          },
          {
            displayName: 'Transferir Colhedor',
            route: '/transferir-colhedor',
            modulo: 'colheita',
          },
          {
            displayName: 'Transferir Turma',
            route: '/transferir-turma',
            modulo: 'colheita',
          },
        ],
      },
      // Dados Frete
      {
        displayName: 'Dados Frete',
        iconName: 'truck',
        children: [
          {
            displayName: 'Fabrica',
            route: '/fabrica',
            modulo: 'fretefabrica',
          },
          {
            displayName: 'Motorista',
            route: '/motorista',
            modulo: 'frete',
          },
          {
            displayName: 'Transportadora',
            route: '/transportadora',
            modulo: 'frete',
          },
          {
            displayName: 'Veiculos para Frete',
            route: '/veiculo-frete',
            modulo: 'frete',
          },
          {
            displayName: 'Fretes',
            route: '/frete',
            modulo: 'frete',
          },
          {
            displayName: 'Histórico Frete',
            route: '/frete-colheita',
            modulo: 'frete',
          },
        ],
      },
      // Parâmetros
      {
        displayName: 'Parâmetros',
        iconName: 'menu',
        children: [
          // Pulverização
          {
            displayName: 'Pulverização',
            route: '/parametros-pulverizacao',
            modulo: 'parametrospulverizacao',
          },
          // Herbicida
          {
            displayName: 'Herbicida',
            route: '/parametros-herbicida',
            modulo: 'parametrosherbicida',
          },
          // Adubação
          {
            displayName: 'Adubação',
            route: '/parametros-adubacao',
            modulo: 'parametrosadubacao',
          },
          // Drench
          {
            displayName: 'Drench',
            route: '/parametros-drench',
            modulo: 'parametrospulverizacao',
          },
          // Tratos
          {
            displayName: 'Tratos',
            route: '/parametros-tratos',
            modulo: 'parametrostratos',
          },
          // Produtos
          {
            displayName: 'Produtos',
            route: '/parametros-produto',
            modulo: 'parametrosproduto',
          },
          // Frotas
          {
            displayName: 'Frotas',
            route: '/parametros-frota',
            modulo: 'parametrosfrota',
          },
          // Cargos
          {
            displayName: 'Cargos',
            route: '/cargos',
            modulo: 'regional',
          },
          // Grupos Cargo
          {
            displayName: 'Grupos Cargo',
            route: '/grupos-cargo',
            modulo: 'grupoCargo',
          },
          // Turno
          {
            displayName: 'Turno',
            route: '/turno',
            modulo: 'turno',
          },
        ],
      },
      // Frotas
      {
        displayName: 'Frotas',
        iconName: 'tractor',
        children: [
          {
            displayName: 'Veículo/Implemento',
            route: '/veiculo-implemento',
            modulo: 'veiculoimplemento',
          },
        ],
      },
      // Estoque
      {
        displayName: 'Estoque',
        iconName: 'shopping-cart',
        children: [
          {
            displayName: 'Entrada Estoque',
            route: '/entrada',
            modulo: 'entrada',
          },
          {
            displayName: 'Transferência Estoque',
            route: '/transferencia-estoque',
            modulo: 'entrada',
          },
          {
            displayName: 'Estoque Atual',
            route: '/listagem-estoque',
            modulo: 'entrada',
          },
        ],
      },
      // Produtos
      {
        displayName: 'Produtos',
        iconName: 'building-store',
        route: '/produto',
        modulo: 'produto',
      },
      // Talhão
      {
        displayName: 'Talhão',
        iconName: 'chart-area',
        route: '/talhao',
        modulo: 'talhao',
      },
      // Acessos
      {
        displayName: 'Acessos',
        iconName: 'fingerprint',
        children: [
          // {
          //   displayName: 'Usuário',
          //   route: '/usuario',
          //   modulo: 'usuario',
          // },
          {
            displayName: 'Opções',
            route: '/opcoes',
            modulo: 'opcoes',
          },
        ],
      },
      // Técnicos
      {
        displayName: 'Técnicos',
        iconName: 'adjustments',
        children: [
          {
            displayName: 'Classes',
            route: '/classes',
            modulo: 'classes',
          },
          // {
          //   displayName: 'Carreadores',
          //   route: '/carreadores',
          //   modulo: 'carreadores',
          // },
          {
            displayName: 'Campo',
            route: '/campo',
            modulo: 'campo',
          },
        ],
      },
    ],
  },
  //Lançamentos
  {
    displayName: 'Lançamentos',
    iconName: 'chart-bar',
    children: [
       {
         iconName: 'icon-spray-can',
         displayName: 'Pulverização',
         route: '/pulverizacao-lancamento',
         modulo: 'dashboard',
         fontFamily: 'font-agro',
         fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-cherry-solid',
        displayName: 'Colheita',
        route: '/colheita-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-grass_three',
        displayName: 'Herbicida',
        route: '/herbicida-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-seedling',
        displayName: 'Adubação',
        route: '/adubacao-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-injection-check',
        displayName: 'Drench',
        route: '/drench-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-gas-pump-solid',
        displayName: 'Abastecimento',
        route: '/abastecimento-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-screwdriver-wrench',
        displayName: 'Troca Óleo',
        route: '/troca-oleo-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-tractor',
        displayName: 'Tratos',
        route: '/tratos-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
    ],
  },
  //Colheita
  {
    displayName: 'Colheita',
    iconName: 'growth',
    children: [
      {
        displayName: 'Lançamentos',
        route: '/lancamento',
        modulo: 'lancamento',
      },
      {
        displayName: 'Estimativa',
        route: '/estimativa',
        modulo: 'estimativa',
      },
      {
        displayName: 'Histórico',
        route: '/historico',
        modulo: 'historico',
      },
    ],
  },
  // {
  //   displayName: 'Oficina',
  //   iconName: 'engine',
  //   route: '/oficina',
  //   modulo: 'oficina',
  // },
  // {
  //   displayName: 'Planejamento',
  //   iconName: 'timeline',
  //   route: '/planejamento',
  //   modulo: 'planejamento',
  // },
];
