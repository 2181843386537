import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService, ResponseService } from "./base.service";
import { Observable } from "rxjs";
import { ClienteModel } from "../models/cliente.model";
import { ClienteEnderecoModel } from "../models/cliente-endereco.model";
import { ClienteParametrosModel } from "../models/cliente-parametros.model";

@Injectable({
  providedIn: "root",
})
export class ClienteService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  gravar(
    cliente: ClienteEnderecoModel
  ): Observable<ResponseService<ClienteEnderecoModel>> {
    const url = `${this.baseUrl}/Clientes/CadastroCompleto`;
    return this.http.post(url, cliente) as Observable<
      ResponseService<ClienteEnderecoModel>
    >;
  }

  alterar(
    cliente: ClienteEnderecoModel
  ): Observable<ResponseService<ClienteEnderecoModel>> {
    const url = `${this.baseUrl}/Clientes/CadastroCompleto`;
    return this.http.put(url, cliente) as Observable<
      ResponseService<ClienteEnderecoModel>
    >;
  }

  buscarClientes(
    parametros: ClienteParametrosModel
  ): Observable<ResponseService<ClienteModel[]>> {
    let params = new HttpParams();

    Object.keys(parametros).forEach((id) => {
      if (!!(parametros as any)[id] || (parametros as any)[id] === 0) {
        params = params.append(id, (parametros as any)[id] as number);
      }
    });

    const url = `${this.baseUrl}/Clientes`;
    return this.http.get(url, { params }) as Observable<
      ResponseService<ClienteModel[]>
    >;
  }

  excluir(id: number): Observable<ResponseService<any>> {
    let params = new HttpParams();

    params = params.append("id", id);
    const url = `${this.baseUrl}/Clientes`;
    return this.http.delete(url, { params }) as Observable<
      ResponseService<ClienteEnderecoModel>
    >;
  }

  desativarRegistro(id: number): Observable<ResponseService<any>> {
    let params = new HttpParams();
    params = params.append('id', id);
    const url = `${this.baseUrl}/Clientes`;
    return this.http.delete(url, { params }) as Observable<
      ResponseService<ClienteEnderecoModel>
    >;
  }
}
