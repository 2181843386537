import { NavItem } from './nav-item/nav-item';

export const navItems: NavItem[] = [
  {
    navCap: 'Início',
  },
  {
    displayName: 'Dashboards',
    iconName: 'home',
    route: '/dashboard',
  },
  {
    navCap: 'Menu',
  },
  //Cadastro
  {
    displayName: 'Cadastro',
    iconName: 'archive',
    children: [
      
      // Dados Fazenda
      {
        displayName: 'Dados Fazendas',
        iconName: 'menu',
        children: [
          {
            displayName: 'Regionais / Fazendas',
            route: '/regional-fazenda',
            modulo: 'regional',
          },
        ],
      },
      // Dados Funcionario
      {
        displayName: 'Dados Funcionários',
        iconName: 'users-group',
        children: [
          // Funcionário Fixo
          {
            displayName: 'Fixo',
            route: '/funcionario-fixo',
            modulo: 'funcionario',
          },
          // Funcionário Administrativo
          {
            displayName: 'Administrativo',
            route: '/funcionario-administrativo',
            modulo: 'funcionario',
          },
          // Funcionário Encarregado
          {
            displayName: 'Encarregado',
            route: '/funcionario-encarregado',
            modulo: 'funcionario',
          },
          // Funcionário Colhedor
          {
            displayName: 'Colhedor',
            route: '/colhedor',
            modulo: 'colheita',
          },
          // Funcionário Todos
          {
            displayName: 'Todos Funcionários',
            route: '/todos-funcionarios',
            modulo: 'colheita',
          },
        ],
      },
      // Dados colheita
      {
        displayName: 'Dados Colheita',
        iconName: 'seeding',
        children: [
          {
            displayName: 'Turma',
            route: '/turma',
            modulo: 'colheita',
          },
          {
            displayName: 'Transferir Colhedor',
            route: '/transferir-colhedor',
            modulo: 'colheita',
          },
          {
            displayName: 'Transferir Turma',
            route: '/transferir-turma',
            modulo: 'colheita',
          },
        ],
      },
      // Dados Frete
      {
        displayName: 'Dados Frete',
        iconName: 'truck',
        children: [
          {
            displayName: 'Motorista',
            route: '/motorista',
            modulo: 'frete',
          },
          {
            displayName: 'Transportadora',
            route: '/transportadora',
            modulo: 'frete',
          },
          {
            displayName: 'Veiculos para Frete',
            route: '/veiculo-frete',
            modulo: 'frete',
          },
          {
            displayName: 'Fretes',
            route: '/frete',
            modulo: 'frete',
          },
          {
            displayName: 'Histórico Frete',
            route: '/frete-colheita',
            modulo: 'frete',
          },
        ],
      },
      // Parâmetros
      {
        displayName: 'Parâmetros',
        iconName: 'menu',
        children: [
          // Aplicativo
          {
            displayName: 'Aplicativo',
            route: '/parametros-aplicativo',
            modulo: 'regional',
          },
          // Cargos
          {
            displayName: 'Cargos',
            route: '/cargos',
            modulo: 'regional',
          },          
        ],
      },
      // Frotas
      {
        displayName: 'Frotas',
        iconName: 'tractor',
        children: [
          {
            displayName: 'Veículo/Implemento',
            route: '/veiculo-implemento',
            modulo: 'veiculoimplemento',
          },
        ],
      },
      // Estoque
      {
        displayName: 'Estoque',
        iconName: 'shopping-cart',
        children: [
          {
            displayName: 'Entrada Estoque',
            route: '/entrada',
            modulo: 'entrada',
          },
          {
            displayName: 'Transferência Estoque',
            route: '/transferencia-estoque',
            modulo: 'entrada',
          },
          {
            displayName: 'Estoque Atual',
            route: '/listagem-estoque',
            modulo: 'entrada',
          },
        ],
      },
      // Produtos
      {
        displayName: 'Produtos',
        iconName: 'building-store',
        route: '/produto',
        modulo: 'produto',
      },
      // Talhão
      {
        displayName: 'Talhão',
        iconName: 'chart-area',
        route: '/talhao',
        modulo: 'talhao',
      },
      // Acessos
      {
        displayName: 'Acessos',
        iconName: 'fingerprint',
        children: [
          // {
          //   displayName: 'Usuário',
          //   route: '/usuario',
          //   modulo: 'usuario',
          // },
          {
            displayName: 'Opções',
            route: '/opcoes',
            modulo: 'opcoes',
          },
        ],
      },
      // Técnicos
      {
        displayName: 'Técnicos',
        iconName: 'adjustments',
        children: [
          {
            displayName: 'Classes',
            route: '/classes',
            modulo: 'classes',
          },
        ],
      },
      // inspeção Pragas
      {
        displayName: 'Inspeção Pragas',
        iconName: 'bug',
        children: [
          // Armadilhas
          {
            displayName: 'Armadilhas',
            route: '/armadilha',
            modulo: 'GrupoCadastroAdmin',
            iconName: 'route',
          },
        ],
      },
      //Administração Agro
      {
        displayName: 'Administração',
        iconName: 'icon-file-shield',
        modulo: 'GrupoCadastroAdmin',
        children: [
          // Dados Cliente
          {
            displayName: 'Dados Cliente',
            iconName: 'menu',
            children: [
              // Cliente
              {
                displayName: 'Cliente',
                route: '/cliente',
                modulo: 'GrupoCadastroAdmin',
              },
            ],
          },
          // Parametros
          {
            displayName: 'Parâmetros',
            iconName: 'menu',
            children: [
              // Pulverização
              {
                displayName: 'Pulverização',
                route: '/parametros-pulverizacao',
                modulo: 'GrupoCadastroAdmin',
              },
              // Herbicida
              {
                displayName: 'Herbicida',
                route: '/parametros-herbicida',
                modulo: 'GrupoCadastroAdmin',
              },
              // Adubação
              {
                displayName: 'Adubação',
                route: '/parametros-adubacao',
                modulo: 'GrupoCadastroAdmin',
              },
              // Drench
              {
                displayName: 'Drench',
                route: '/parametros-drench',
                modulo: 'GrupoCadastroAdmin',
              },
              // Tratos
              {
                displayName: 'Tratos',
                route: '/parametros-tratos',
                modulo: 'GrupoCadastroAdmin',
              },
              // Produtos
              {
                displayName: 'Produtos',
                route: '/parametros-produto',
                modulo: 'GrupoCadastroAdmin',
              },
              // Frotas
              {
                displayName: 'Frotas',
                route: '/parametros-frota',
                modulo: 'GrupoCadastroAdmin',
              },
              {
                displayName: 'Armadilhas',
                iconName: 'icon-bug-slash',
                route: '/dashboard',
                modulo: 'GrupoCadastroAdmin',
              },
              // Grupos Cargo
              {
                displayName: 'Grupos Cargo',
                route: '/grupos-cargo',
                modulo: 'GrupoCadastroAdmin',
              },
              // Turno
              {
                displayName: 'Turno',
                route: '/turno',
                modulo: 'GrupoCadastroAdmin',
              },
            ]
          },
          // Dados Frete
          {
            displayName: 'Dados Frete',
            iconName: 'truck',
            children: [
              {
                displayName: 'Fabrica',
                route: '/fabrica',
                modulo: 'GrupoCadastroAdmin',
              },
            ]
          },
          // Técnicos
          {
            displayName: 'Técnicos',
            iconName: 'adjustments',
            children: [
              {
                displayName: 'Campo',
                route: '/campo',
                modulo: 'GrupoCadastroAdmin',
              },
            ],
          },
        ],
      },
    ]
  },
  //Lançamentos
  {
    displayName: 'Lançamentos',
    iconName: 'chart-bar',
    children: [
       {
         iconName: 'icon-spray-can',
         displayName: 'Pulverização',
         route: '/pulverizacao-lancamento',
         modulo: 'dashboard',
         fontFamily: 'font-agro',
         fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-cherry-solid',
        displayName: 'Colheita',
        route: '/colheita-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-grass_three',
        displayName: 'Herbicida',
        route: '/herbicida-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-seedling',
        displayName: 'Adubação',
        route: '/adubacao-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-injection-check',
        displayName: 'Drench',
        route: '/drench-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-gas-pump-solid',
        displayName: 'Abastecimento',
        route: '/abastecimento-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-screwdriver-wrench',
        displayName: 'Troca Óleo',
        route: '/troca-oleo-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
      {
        iconName: 'icon-tractor',
        displayName: 'Tratos',
        route: '/tratos-lancamento',
        modulo: 'dashboard',
        fontFamily: 'font-agro',
        fontSize: 'font-size-large'
      },
    ],
  
  },

  //Administração Agro
  // {
  //   displayName: 'Administração',
  //   iconName: 'icon-file-shield',
  //   modulo: 'GrupoCadastroAdmin',
  //   children: [
  //     // inspeção Pragas
  //     {
  //       displayName: 'Inspeção Pragas',
  //       iconName: 'menu',
  //       children: [
  //         // Armadilhas
  //         {
  //           displayName: 'Armadilhas',
  //           route: '/armadilha',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //       ],
  //     },
  //     // Dados Cliente
  //     {
  //       displayName: 'Dados Cliente',
  //       iconName: 'menu',
  //       children: [
  //         // Cliente
  //         {
  //           displayName: 'Cliente',
  //           route: '/cliente',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //       ],
  //     },
  //     // Parametros
  //     {
  //       displayName: 'Parâmetros',
  //       iconName: 'menu',
  //       children: [
  //         // Pulverização
  //         {
  //           displayName: 'Pulverização',
  //           route: '/parametros-pulverizacao',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Herbicida
  //         {
  //           displayName: 'Herbicida',
  //           route: '/parametros-herbicida',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Adubação
  //         {
  //           displayName: 'Adubação',
  //           route: '/parametros-adubacao',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Drench
  //         {
  //           displayName: 'Drench',
  //           route: '/parametros-drench',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Tratos
  //         {
  //           displayName: 'Tratos',
  //           route: '/parametros-tratos',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Produtos
  //         {
  //           displayName: 'Produtos',
  //           route: '/parametros-produto',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Frotas
  //         {
  //           displayName: 'Frotas',
  //           route: '/parametros-frota',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         {
  //           displayName: 'Armadilhas',
  //           iconName: 'icon-bug-slash',
  //           route: '/dashboard',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Grupos Cargo
  //         {
  //           displayName: 'Grupos Cargo',
  //           route: '/grupos-cargo',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //         // Turno
  //         {
  //           displayName: 'Turno',
  //           route: '/turno',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //       ]
  //     },
  //     // Dados Frete
  //     {
  //       displayName: 'Dados Frete',
  //       iconName: 'truck',
  //       children: [
  //         {
  //           displayName: 'Fabrica',
  //           route: '/fabrica',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //       ]
  //     },
  //     // Técnicos
  //     {
  //       displayName: 'Técnicos',
  //       iconName: 'adjustments',
  //       children: [
  //         {
  //           displayName: 'Campo',
  //           route: '/campo',
  //           modulo: 'GrupoCadastroAdmin',
  //         },
  //       ],
  //     },
  //   ],
  // },
];
