import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ username, password }) => {
        this.loadingService.startLoading();
        return this.authService.login(username, password).pipe(
          map(({ data, error, messages, action }) => {
            this.loadingService.stopLoading();
            if (!error) {
              const {
                jwtToken,
                email,
                funcionarioNome,
                nomeUsuario,
                refreshToken,
                verificado,
                funcionario,
              } = data!;
              return AuthActions.loginSuccess({
                access_token: jwtToken,
                refreshToken,
                permissions: '',
                email,
                funcionarioNome,
                nomeUsuario,
                verificado,
                mensagem: '',
                funcionario,
                permissao: '',
              });
            } else {
              if (
                messages.length > 0 &&
                action == 'TelaAlterarSenha'
              ) {
                localStorage.clear();
                localStorage.setItem('nomeUsuarioRecovery', username);
                localStorage.setItem('passwordRecovery', password);
                this.router.navigate(['/authentication/alter-password']);
              }
              return AuthActions.loginFailure({ error: messages });
            }
          }),
          catchError((error) => {
            this.loadingService.stopLoading();
            return of(AuthActions.loginFailure({ error }));
          })
        );
      })
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        tap(
          ({
            access_token,
            funcionarioNome,
            nomeUsuario,
            funcionario,
            email,
          }) => {
            this.authService.saveToken(access_token!);
            this.authService.saveDadosUsuario({
              nome: funcionarioNome!,
              usuario: nomeUsuario!,
              funcionario: funcionario!,
              email: email!,
            });
            this.router.navigate(['/dashboard']); // Redirecionar para a página de dashboard após o login bem-sucedido
          }
        )
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          this.authService.logout(); // Limpar dados de autenticação ao fazer logout
          this.router.navigate(['/authentication/login']); // Redirecionar para a página de login após o logout
        })
      ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      switchMap(() =>
        this.authService.refreshToken().pipe(
          map(({ access_token }) =>
            AuthActions.refreshTokenSuccess({ access_token })
          ),
          catchError((error) => of(AuthActions.refreshTokenFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService
  ) {}
}
