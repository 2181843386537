<div class="flex">
  <div
    class="user-profile profile-bar"
    style="background: url(assets/images/backgrounds/bg-orange.jpeg) no-repeat"
  >
    <!-- User profile image -->
    <div class="profile-img">
      <!-- <img
        src="assets/images/profile/AgroControle_FundoBranco.png"
        alt="user"
        width="150"
        class="rounded-circle"
      /> -->
    </div>
    <!-- User profile text-->
    <!-- ============================================================== -->
    <!-- Profile - style you can find in header.scss -->
    <!-- ============================================================== -->
    <div class="profile-text">
      <a class="d-flex align-items-center text-white w-100 f-s-16">
        {{ nomeUsuario }}
      </a>
    </div>
  </div>
</div>
