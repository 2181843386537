import {
  createAction,
  createFeatureSelector,
  createSelector,
  props,
} from '@ngrx/store';
import { AuthState } from '../app.state';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const getAccessToken = createSelector(selectAuthState,(s) => {
  return s.access_token || ''
});

export const updateAccessToken = createAction(
  '[Auth] Update Access Token',
  props<{ accessToken: string }>()
);

export const getPermissao = createSelector(
  selectAuthState,
  (state: AuthState) => state.permissao || ''
);

export const getUserPermissions = createSelector(selectAuthState, (s) => {
  return s.permissions || ''
});

export const getEmail = createSelector(
  selectAuthState,
  (state: AuthState) => state.email || ''
);

export const getFuncionarioNome = createSelector(
  selectAuthState,
  (state: AuthState) => state.funcionarioNome || ''
);

export const getDadosUsuario = createSelector(
  selectAuthState,
  (state: AuthState) => ({
    nome: state.funcionarioNome || '',
    usuario: state.nomeUsuario,
    funcionario: state.funcionario,
    email: state.email,
  })
);

export const getNomeUsuario = createSelector(
  selectAuthState,
  (state: AuthState) => state.nomeUsuario || ''
);

export const getVerificado = createSelector(
  selectAuthState,
  (state: AuthState) => state.verificado || ''
);

export const getMensagem = createSelector(
  selectAuthState,
  (state: AuthState) => state.mensagem || ''
);
