import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataTokenModel } from '@src/app/models/data-token.model';
import { FazendaModel } from '@src/app/models/fazenda.model';
import { AuthService } from '@src/app/services/auth.service';
import { ClienteService } from '@src/app/services/cliente.service';
import { FazendaFuncionarioService } from '@src/app/services/fazenda-funcionario.service';
import { LoadingService } from '@src/app/services/loading.service';

@Component({
  selector: 'app-perfil',
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    // AppSalesOverviewComponent,
    // AppSalesOurVisitorsComponent,
    // AppBlogCardComponent,
    // AppNewsletterCampaignComponent,
    // AppBandwidthUsageComponent,
    // AppDownloadCountComponent,
    // AppWeatherCardComponent,
    // AppProfileCardComponent,
    // AppMyContactsComponent,
    // AppActivityTimelineComponent,
  ],
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PerfilComponent implements OnInit {
  dadosToken: DataTokenModel;
  funcionarioNome: string | undefined;
  cargo: string;
  cliente: string;
  fazendas: FazendaModel[] = [];

  constructor(
    private authService: AuthService,
    private funcionarioService: FazendaFuncionarioService,
    private clienteoService: ClienteService,
    private loadingService: LoadingService,
    private router: Router,
    public readonly dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingService.startLoading();
    Promise.all([this.carregarDadosToken()])
      .then(() => {
        this.loadingService.stopLoading();
      })
      .catch(() => {
        this.loadingService.stopLoading();
      });
  }

  carregarDadosToken() {
    this.authService.getDataToken().subscribe((data) => {
      this.dadosToken = data;
      this.funcionarioService
        .buscarFazendaFuncionario({
          funcionarioId: this.dadosToken.FuncionarioId,
          include: 'Fazenda,Funcionario,Funcionario.Cargo',
        })
        .subscribe((retorno) => {
          if (retorno.data && retorno.data.length > 0) {
            const data = retorno?.data[0] || {};
            this.funcionarioNome = data.funcionario?.nome;
            this.cargo = data.funcionario?.cargo?.nome || '';
            this.fazendas = retorno.data.map((d) => d.fazenda!);
          }
        });
      this.clienteoService
        .buscarClientes({
          id: this.dadosToken.ClienteId,
        })
        .subscribe((retorno) => {
          if (retorno.data && retorno.data.length > 0) {
            const data = retorno.data[0] || {};
            this.cliente = data.nome || '';
          }
        });
    });
  }

  changePassword() {
    this.router.navigate(['authentication/alter-password']);
  }
}
