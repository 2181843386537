import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface AuthState {
  access_token: string | null;
  refreshToken: string | null;
  email: string | null;
  funcionarioNome: string | null;
  nomeUsuario: string | null;
  verificado: boolean;
  error: any;
}

export const initialState: AuthState = {
  access_token: null,
  refreshToken: null,
  email: null,
  funcionarioNome: null,
  nomeUsuario: null,
  verificado: false,
  error: null,
};

export const authReducer = createReducer(
  initialState,
  on(
    AuthActions.loginSuccess,
    (
      state,
      {
        access_token,
        refreshToken,
        email,
        funcionarioNome,
        nomeUsuario,
        verificado,
        mensagem,
        funcionario,
      }
    ) => ({
      ...state,
      access_token,
      refreshToken,
      email,
      funcionarioNome,
      nomeUsuario,
      verificado,
      error: mensagem,
      funcionario,
    })
  ),
  on(AuthActions.loginFailure, (state, { error }) => ({
    ...state,
    access_token: null,
    refreshToken: null,
    email: null,
    funcionarioNome: null,
    nomeUsuario: null,
    verificado: false,
    mensagem: error,
    error: error,
    funcionario: null,
  })),
  on(AuthActions.refreshTokenSuccess, (state, { access_token }) => ({
    ...state,
    access_token,
    error: null,
  })),

  on(AuthActions.refreshTokenFailure, (state, { error }) => ({
    ...state,
    access_token: null,
    refreshToken: null,
    error,
  })),
  on(AuthActions.updatePermissao, (state, { permissao }) => ({
    ...state,
    permissao,
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    access_token: null,
    refresh_token: null,
    email: null,
    funcionarioNome: null,
    nomeUsuario: null,
    verificado: false,
    error: null,
    funcionario: null,
  }))
);
