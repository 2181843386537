import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AuthService } from "../services/auth.service";
import { Store } from "@ngrx/store";
import * as AuthActions from "../store/auth/auth.actions";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private store: Store) {}

  private tratarErro(
    request: HttpRequest<any>,
    httpHandler: HttpHandler,
    error: any
  ): Observable<HttpEvent<any>> {
    if (error.status === 401) {
      // Realiza a renovação do token usando o refresh token
      return new Observable<HttpEvent<any>>((observer) => {
        this.authService.refreshToken().subscribe({
          next: (accessToken) => {
            // Clona a requisição original e adiciona o novo token de acesso
            const authRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`,
              },
            });
            // Faz a nova requisição com o token renovado
            httpHandler.handle(authRequest).subscribe({
              next: (n) => observer.next(n),
              error: (e) => {
                this.store.dispatch(AuthActions.logout());
                observer.error(e);
              },
              complete: () => observer.complete()
            });
          },
          error: (refreshError) => {
            // Se ocorrer um erro ao renovar o token, redireciona para a página de login
            this.store.dispatch(AuthActions.logout());
            observer.error(refreshError);
          }
        });
      });
    }
    return throwError(() => new Error(error));
  }

  intercept(
    request: HttpRequest<any>,
    httpHandler: HttpHandler
  ): Observable<HttpEvent<any>> {
    return new Observable<HttpEvent<any>>((observer) => {
      this.authService.getAccessToken().subscribe({
        next: (accessToken) => {
          if (accessToken) {
            // Clona a requisição e adiciona o cabeçalho Authorization com o token de acesso
            const authRequest = request.clone({
              setHeaders: {
                Authorization: `Bearer ${accessToken}`,
              },
            });

            // Prossegue com a requisição modificada
            httpHandler.handle(authRequest).subscribe({
              next: (event) => observer.next(event),
              error: (error) => {
                this.tratarErro(request, httpHandler, error).subscribe({
                  next: (event) => observer.next(event),
                  error: (error) => observer.error(error),
                  complete: () => observer.complete()
              });
              },
              complete: () => observer.complete()
            });
          } else {
            // Se o token de acesso não estiver disponível, prossegue com a requisição original
            httpHandler.handle(request).subscribe({
              next: (event) => observer.next(event),
              error: (error) => {
                this.tratarErro(request, httpHandler, error).subscribe({
                  next: (event) => observer.next(event),
                  error: (error) => observer.error(error),
                  complete: () => observer.complete()
                });
              },
              complete: () => observer.complete()
            });
          }
        },
        error: (error) => {
          this.tratarErro(request, httpHandler, error).subscribe({
            next: (event) => observer.next(event),
            error: (error) => observer.error(error),
            complete: () => observer.complete()
          });
        }
      });
    });
  }
}
