import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { BaseService, ResponseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { TokenModel } from '../models/token.model';
import { AppState } from '../store/app.state';
import { Store, select } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import {
  getAccessToken,
  getDadosUsuario,
  getPermissao,
  getUserPermissions,
} from '../store/auth/auth.selectors';
import * as AuthActions from '../store/auth/auth.actions';
import { DataTokenModel } from '../models/data-token.model';
import { DadosUsuarioModel } from '../models/dados-usuario.model';
import { PermissaoTelaModel } from '../models/permissao-tela.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(http: HttpClient, private store: Store<AppState>) {
    super(http);
  }

  login(
    nomeUsuario: string,
    senha: string
  ): Observable<ResponseService<TokenModel>> {
    const url = `${this.baseUrl}/Usuario/Autenticar`;
    const body = { nomeUsuario, senha };
    return this.http.post(url, body) as Observable<ResponseService<TokenModel>>;
  }

  recuperarSenha(
    nomeUsuario: string
  ): Observable<ResponseService<any>> {
    const url = `${this.baseUrl}/Usuario/esqueci-senha`;
    const body = { nomeUsuario };
    return this.http.post(url, body) as Observable<ResponseService<TokenModel>>;
  }

  resetPassword(
    nomeUsuario: string,
    senhaAntiga: string,
    senha: string,
    confirmarSenha: string
  ): Observable<ResponseService<any>> {
    const url = `${this.baseUrl}/Usuario/resetar-senha`;
    const body = { nomeUsuario, senhaAntiga, senha, confirmarSenha };
    return this.http.post(url, body) as Observable<ResponseService<TokenModel>>;
  }

  logout() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('dadosUsuario');
    localStorage.clear(); 
    sessionStorage.clear();  
  }

  // Método para gravar o token no localStorage
  saveToken(token: string): void {
    localStorage.clear();
    localStorage.setItem('token', token);
  }

  saveDadosUsuario(dados: DadosUsuarioModel): void {
    localStorage.setItem('dadosUsuario', JSON.stringify(dados));
  }
  saveFluxoAcesso(dados: PermissaoTelaModel[]): void {
    localStorage.setItem('fluxoAcesso', JSON.stringify(dados));
  }

  getAccessToken(): Observable<string> {
    return this.store.
      select(getAccessToken).pipe(take(1),
      map((token) => {
        if (token) {
          return token;
        } else {
          this.store.dispatch(
            AuthActions.refreshTokenSuccess({
              access_token: localStorage.getItem('token') || '',
            })
          );
          return localStorage.getItem('token') || '';
        }
      })
      );
  }

  getNomeUsuario(): Observable<DadosUsuarioModel> {
    return this.store.
      select(getDadosUsuario).pipe(take(1),
      map((dados) => {
        if (dados.nome !== '') {
          return dados;
        } else {
          return JSON.parse(localStorage.getItem('dadosUsuario') || '{}') || {};
        }
      })
    );
  }

  getFluxoAcesso(): PermissaoTelaModel[] {
    return JSON.parse(localStorage.getItem('fluxoAcesso') || '{}') || {};
  }

  getPermissao(): Observable<string> {
    return this.store.
      select(getPermissao).pipe(take(1),
      map((dados) => {
        return dados;
      })
    );
  }

  getDataToken(): Observable<DataTokenModel> {
    return new Observable<DataTokenModel>((observer) => {
      this.store.select(getAccessToken).pipe(take(1))
      .subscribe({
        next: (token) => {
          if (token) {
            let data = jwt_decode(token) as DataTokenModel;
            observer.next(data);
          }
          //  else {
          //   observer.next({});
          // }
        }, error: (error) => {
          observer.error(error);
        },complete: () => observer.complete()
      }
      );
    });
  }

  refreshToken(): Observable<any> {
    const url = `${this.baseUrl}/Usuario/refresh-token`;
    // Obtenha o token de atualização armazenado (geralmente no localStorage ou em algum estado global)
    const refreshToken = 'token_de_atualizacao';
    const body = { refreshToken };

    return this.http.post(url, body);
  }

  hasPermissions(requiredPermissions: string[]): boolean {
    const userPermissions = this.store.select(getUserPermissions).pipe(take(1)); // Obtém as permissões do usuário do estado

    // Verifica se o usuário possui todas as permissões necessárias
    return requiredPermissions.every((permission) =>
      userPermissions.subscribe({next: (a) => a.includes(permission)})
    );
  }
}
