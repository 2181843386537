import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { authGuard } from './guard/auth.guard';
import { PerfilComponent } from './pages/perfil/perfil.component';

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    data: { breadcrumb: 'Home' },
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/pages.module').then((m) => m.PagesModule),
          data: {
            requiredPermissions: ['dashboard'],
            'reloadComponent': true
          },
         canMatch: [authGuard],
      },
      {
        path: 'perfil',
        component: PerfilComponent,
        data: {
          title: 'Perfil',
          urls: [{ title: 'Perfil', url: 'perfil' }, { title: 'Perfil' }],
        },
      },
      {
        path: 'cliente',
        loadChildren: () =>
          import(
            './pages/cadastros/cliente/cliente.module'
          ).then((m) => m.ClienteModule),
        data: {
          requiredPermissions: ['cliente'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'funcionario-fixo',
        loadChildren: () =>
          import(
            './pages/cadastros/funcionarios/fixo/funcionario-fixo.module'
          ).then((m) => m.FuncionarioFixoModule),
        data: {
          requiredPermissions: ['cliente'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'funcionario-administrativo',
        loadChildren: () =>
          import(
            './pages/cadastros/funcionarios/administrativo/funcionario-administrativo.module'
          ).then((m) => m.FuncionarioAdministrativoModule),
        data: {
          requiredPermissions: ['cliente'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'funcionario-encarregado',
        loadChildren: () =>
          import(
            './pages/cadastros/funcionarios/encarregado/funcionario-encarregado.module'
          ).then((m) => m.FuncionarioEncarregadoModule),
        data: {
          requiredPermissions: ['cliente'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'regional-fazenda',
        loadChildren: () =>
          import(
            './pages/cadastros/dados-fazenda/regional-fazenda/regional-fazenda.module'
          ).then((m) => m.RegionalFazendaModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      // {
      //   path: 'colheita',
      //   loadChildren: () =>
      //     import(
      //       './pages/cadastros/dados-colheita/colheita/colheita.module'
      //     ).then((m) => m.ColheitaModule),
      //   data: {
      //     requiredPermissions: ['colheita'],
      //   },
      //   canMatch: [authGuard],
      // },
      {
        path: 'turma',
        loadChildren: () =>
          import(
            './pages/cadastros/dados-colheita/turma/turma.module'
          ).then((m) => m.TurmaModule),
        data: {
          requiredPermissions: ['colheita'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'colhedor',
        loadChildren: () =>
          import(
            './pages/cadastros/dados-colheita/colhedor/colhedor.module'
          ).then((m) => m.ColhedorModule),
        data: {
          requiredPermissions: ['colheita'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'transferir-colhedor',
        loadChildren: () =>
          import(
            './pages/cadastros/dados-colheita/transferir-colhedores/transferir-colhedores.module'
          ).then((m) => m.TransferirColhedoresModule),
        data: {
          requiredPermissions: ['colheita'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'transferir-turma',
        loadChildren: () =>
          import(
            './pages/cadastros/dados-colheita/transferir-turma/transferir-turma.module'
          ).then((m) => m.TransferirTurmaModule),
        data: {
          requiredPermissions: ['colheita'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'talhao',
        loadChildren: () =>
          import('./pages/cadastros/talhao/talhao.module').then(
            (m) => m.TalhaoModule
          ),
        data: {
          requiredPermissions: ['talhao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'entrada',
        loadChildren: () =>
          import('./pages/cadastros/estoque/entrada/entrada.module').then(
            (m) => m.EntradaModule
          ),
        data: {
          requiredPermissions: ['entrada'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'transferencia-estoque',
        loadChildren: () =>
          import('./pages/cadastros/estoque/transferencia-estoque/transferencia-estoque.module').then(
            (m) => m.TransferenciaEstoqueModule
          ),
        data: {
          requiredPermissions: ['entrada'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'listagem-estoque',
        loadChildren: () =>
          import('./pages/cadastros/estoque/listagem-estoque/listagem-estoque.module').then(
            (m) => m.ListagemEstoqueModule
          ),
        data: {
          requiredPermissions: ['entrada'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-pulverizacao',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/pulverizacao/parametros-pulverizacao.module'
          ).then((m) => m.ParametroPulverizacaoModule),
        data: {
          requiredPermissions: ['parametrospulverizacao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-herbicida',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/herbicida/parametros-herbicida.module'
          ).then((m) => m.ParametroHerbicidaModule),
        data: {
          requiredPermissions: ['parametrosherbicida'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-adubacao',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/adubacao/parametros-adubacao.module'
          ).then((m) => m.ParametroAdubacaoModule),
        data: {
          requiredPermissions: ['parametrosadubacao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-drench',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/drench/parametros-drench.module'
          ).then((m) => m.ParametroDrenchModule),
        data: {
          requiredPermissions: ['parametrospulverizacao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-tratos',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/tratos/parametros-tratos.module'
          ).then((m) => m.ParametroTratosModule),
        data: {
          requiredPermissions: ['parametrostratos'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-frota',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/frotas/parametros-frota.module'
          ).then((m) => m.ParametroFrotaModule),
        data: {
          requiredPermissions: ['parametrosfrota'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'veiculo-implemento',
        loadChildren: () =>
          import(
            './pages/cadastros/frotas-implementos/veiculo-implemento/veiculo-implemento.module'
          ).then((m) => m.VeiculoImplementoModule),
        data: {
          requiredPermissions: ['veiculoimplemento'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'parametros-produto',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/produtos/parametros-produto.module'
          ).then((m) => m.ParametroProdutoModule),
        data: {
          requiredPermissions: ['parametrosproduto'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'produto',
        loadChildren: () =>
          import('./pages/cadastros/produtos/produto/produto.module').then(
            (m) => m.ProdutoModule
          ),
        data: {
          requiredPermissions: ['produto'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'perfil',
        loadChildren: () =>
          import('./pages/cadastros/acessos/opcoes/perfil/perfil.module').then(
            (m) => m.PerfilModule
          ),
        data: {
          requiredPermissions: ['perfil'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'opcoes',
        loadChildren: () =>
          import('./pages/cadastros/acessos/opcoes/opcoes.module').then(
            (m) => m.OpcoesModule
          ),
        data: {
          requiredPermissions: ['opcoes'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'permissao',
        loadChildren: () =>
          import(
            './pages/cadastros/acessos/opcoes/permissao/permissao.module'
          ).then((m) => m.PermissaoModule),
        data: {
          requiredPermissions: ['permissao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'cargos',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/cargo/cargo.module'
          ).then((m) => m.CargoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'grupos-cargo',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/grupo-cargo/grupo-cargo.module'
          ).then((m) => m.GrupoCargoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'motorista',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/motorista/motorista.module'
          ).then((m) => m.MotoristaModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'fabrica',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/fabrica/fabrica.module'
          ).then((m) => m.FabricaModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'transportadora',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/transportadora/transportadora.module'
          ).then((m) => m.TransportadoraModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'veiculo-frete',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/veiculo-frete/veiculo-frete.module'
          ).then((m) => m.VeiculoFreteModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'frete',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/frete/frete.module'
          ).then((m) => m.FreteModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'frete-colheita',
        loadChildren: () =>
          import(
            './pages/cadastros/fretes/frete-colheita/frete-colheita.module'
          ).then((m) => m.FreteColheitaModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'turno',
        loadChildren: () =>
          import(
            './pages/cadastros/parametros/turno/turno.module'
          ).then((m) => m.TurnoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'usuario',
        loadChildren: () =>
          import('./pages/cadastros/acessos/usuario/usuario.module').then(
            (m) => m.UsuarioModule
          ),
        data: {
          requiredPermissions: ['usuario'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'carreadores',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/carreadores/carreadores.module'
          ).then((m) => m.CarreadoresModule),
        data: {
          requiredPermissions: ['carreadores'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classe-geral',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/classes/classe-geral/classe-geral.module'
          ).then((m) => m.ClasseGeralModule),
        data: {
          requiredPermissions: ['classe-geral'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'campo',
        loadChildren: () =>
          import('./pages/cadastros/tecnicos/campo/campo.module').then(
            (m) => m.CampoModule
          ),
        data: {
          requiredPermissions: ['campo'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classe-pulverizacao',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/classes/classe-pulverizacao/classe-pulverizacao.module'
          ).then((m) => m.ClassePulverizacaoModule),
        data: {
          requiredPermissions: ['classe-pulverizacao'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classe-herbicida',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/classes/classe-herbicida/classe-herbicida.module'
          ).then((m) => m.ClasseHerbicidaModule),
        data: {
          requiredPermissions: ['classe-herbicida'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classes',
        loadChildren: () =>
          import('./pages/cadastros/tecnicos/classes/classes.module'
          ).then((m) => m.ClassesModule),
        data: {
          requiredPermissions: ['classes'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classe-corretivo',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/classes/classe-corretivo/classe-corretivo.module'
          ).then((m) => m.ClasseCorretivoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'classe-drench',
        loadChildren: () =>
          import(
            './pages/cadastros/tecnicos/classes/classe-drench/classe-drench.module'
          ).then((m) => m.ClasseDrenchModule),
        data: {
          requiredPermissions: ['classe-drench'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'usuario-acesso',
        loadChildren: () =>
          import(
            './pages/cadastros/acessos/opcoes/usuario-acesso/usuario-acesso.module'
          ).then((m) => m.UsuarioAcessoModule),
        data: {
          requiredPermissions: ['usuario-acesso'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'pulverizacao-lancamento',
        loadChildren: () =>
          import(
            './pages/lancamentos/pulverizacao/pulverizacao-lancamento.module'
          ).then((m) => m.PulverizacaoLancamentoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'colheita-lancamento',
        loadChildren: () =>
          import(
            './pages/lancamentos/colheita/colheita-lancamento.module'
          ).then((m) => m.ColheitaLancamentoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'herbicida-lancamento',
        loadChildren: () =>
          import(
            './pages/lancamentos/herbicida/herbicida-lancamento.module'
          ).then((m) => m.HerbicidaLancamentoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'adubacao-lancamento',
        loadChildren: () =>
          import(
            './pages/lancamentos/adubacao/adubacao-lancamento.module'
          ).then((m) => m.AdubacaoLancamentoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
      {
        path: 'drench-lancamento',
        loadChildren: () =>
          import(
            './pages/lancamentos/drench/drench-lancamento.module'
          ).then((m) => m.DrenchLancamentoModule),
        data: {
          requiredPermissions: ['regional'],
        },
        canMatch: [authGuard],
      },
    
    ],
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./pages/authentication/authentication.module').then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'authentication/error',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
