import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { CoreService } from 'src/app/services/core.service';
import { AppSettings } from 'src/app/app.config';
import { navItems } from './vertical/sidebar/sidebar-data';
import { NavService } from '../../services/nav.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import {
  AppSearchDialogComponent,
  HeaderComponent,
} from './vertical/header/header.component';
import { AppHorizontalHeaderComponent } from './horizontal/header/header.component';
import { AppHorizontalSidebarComponent } from './horizontal/sidebar/sidebar.component';
import { SidebarComponent } from './vertical/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './shared/customizer/customizer.component';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AppNavItemComponent } from './vertical/sidebar/nav-item/nav-item.component';
import { PerfilUsuarioService } from '@src/app/services/perfil-usuario.service';
import { AuthService } from '@src/app/services/auth.service';
import { NavItem } from './vertical/sidebar/nav-item/nav-item';
import { somenteAgroControle } from '@src/app/guard/auth.guard';

const MOBILE_VIEW = 'screen and (max-width: 768px)';
const TABLET_VIEW = 'screen and (min-width: 769px) and (max-width: 1024px)';
const MONITOR_VIEW = 'screen and (min-width: 1024px)';
const BELOWMONITOR = 'screen and (max-width: 1023px)';
@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  standalone: true,
  imports: [
    NgScrollbarModule,
    HeaderComponent,
    AppHorizontalHeaderComponent,
    AppHorizontalSidebarComponent,
    SidebarComponent,
    AppBreadcrumbComponent,
    AppSearchDialogComponent,
    CustomizerComponent,
    MaterialModule,
    RouterModule,
    CommonModule,
    AppNavItemComponent,
  ],
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
})
export class FullComponent implements OnInit {
  navItems: any;
  @ViewChild('leftsidenav')
  public sidenav: MatSidenav;
  resView = false;
  //get options from service
  options = this.settings.getOptions();
  navopt = this.navService.showClass;
  private layoutChangesSubscription = Subscription.EMPTY;
  private isMobileScreen = false;
  private isContentWidthFixed = true;
  private isCollapsedWidthFixed = false;
  private htmlElement!: HTMLHtmlElement;

  get isOver(): boolean {
    return this.isMobileScreen;
  }

  get isTablet(): boolean {
    return this.resView;
  }

  constructor(
    private settings: CoreService,
    private mediaMatcher: MediaMatcher,
    private navService: NavService,
    private breakpointObserver: BreakpointObserver,
    private readonly perfilUsuarioService: PerfilUsuarioService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
    this.htmlElement = document.querySelector('html')!;
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_VIEW, TABLET_VIEW, MONITOR_VIEW, BELOWMONITOR])
      .subscribe((state) => {
        // SidenavOpened must be reset true when layout changes
        this.options.sidenavOpened = true;
        this.isMobileScreen = state.breakpoints[MOBILE_VIEW];

        if (this.options.sidenavCollapsed == false) {
          this.options.sidenavCollapsed = state.breakpoints[TABLET_VIEW];
        }
        this.isContentWidthFixed = state.breakpoints[MONITOR_VIEW];
        this.resView = state.breakpoints[BELOWMONITOR];
      });

    // Initialize project theme with options
    this.receiveOptions(this.options);
  }

  ngOnInit(): void {
    this.getMenu();
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  getMenu = () => {
    let fluxoAcesso = this.authService.getFluxoAcesso();   
    this.authService.getDataToken().subscribe({
      next: async (token) => {                
        if (token && token.UsuarioId) {
          if(fluxoAcesso && Array.isArray(fluxoAcesso)){
            this.navItems = this.filtrarModulosPorPermissao(
              navItems,
              fluxoAcesso.map((a) =>
                a.nomeModulo.toLowerCase().split(' ').join('-')
              ) || [],
              token.ClienteNome == 'AgroControle'
            );            
          }else{
            await this.perfilUsuarioService
            .buscaFluxoAcesso({
              usuarioId: token.UsuarioId,
            })
            .subscribe({
              next: (retorno) => {
                  this.navItems = this.filtrarModulosPorPermissao(
                    navItems,
                    retorno.data?.map((a) =>
                      a.nomeModulo.toLowerCase().split(' ').join('-')
                    ) || [],
                    token.ClienteNome == 'AgroControle'
                  );
                }
              });
          }
        }
    }});
  };

  private temPermissao(
    child: NavItem,
    permissoesUsuario: string[],
    isAgroControle: boolean
  ): boolean {
    return (
      !child.modulo ||
      (!isAgroControle &&
        !somenteAgroControle.includes(child.modulo) &&
        permissoesUsuario.includes(child.modulo)) ||
      (isAgroControle &&
        (somenteAgroControle.includes(child.modulo) ||
          permissoesUsuario.includes(child.modulo)))
    );
  }

  private podeAcessarModulo(
    modulo: NavItem,
    permissoesUsuario: string[],
    isAgroControle: boolean
  ): boolean {
    if (!modulo.children || modulo.children.length === 0) {
      return true;
    }

    return modulo.children.some((child) =>
      this.temPermissao(child, permissoesUsuario, isAgroControle)
    );
  }

  private filtrarFilhosPorPermissao1(
    children: NavItem[] | undefined,
    permissoesUsuario: string[],
    isAgroControle: boolean
  ): NavItem[] | undefined {  
    if (!children) {
      return undefined;
    }
    const childrenRetorno = this.filtrarModulosPorPermissao(
      children,
      permissoesUsuario,
      isAgroControle
    );
    return childrenRetorno?.filter((child) =>
      this.temPermissao(child, permissoesUsuario, isAgroControle)
    );
  }

  filtrarModulosPorPermissao(
    modulos: NavItem[],
    permissoesUsuario: string[],
    isAgroControle: boolean = false
  ): NavItem[] {
    return modulos
      .filter((modulo) =>
        this.podeAcessarModulo(modulo, permissoesUsuario, isAgroControle)
      )
      .map((modulo) => ({
        ...modulo,
        children: this.filtrarFilhosPorPermissao1(
          modulo.children,
          permissoesUsuario,
          isAgroControle
        ),
      }));
  }

  toggleCollapsed() {
    this.isContentWidthFixed = false;
    this.options.sidenavCollapsed = !this.options.sidenavCollapsed;
    this.resetCollapsedState();
  }

  resetCollapsedState(timer = 400) {
    setTimeout(() => this.settings.setOptions(this.options), timer);
  }

  onSidenavClosedStart() {
    this.isContentWidthFixed = false;
  }

  onSidenavOpenedChange(isOpened: boolean) {
    this.isCollapsedWidthFixed = !this.isOver;
    this.options.sidenavOpened = isOpened;
    this.settings.setOptions(this.options);
  }

  receiveOptions(options: AppSettings): void {
    this.options = options;
    this.toggleDarkTheme(options);
  }

  toggleDarkTheme(options: AppSettings) {
    if (options.theme === 'dark') {
      this.htmlElement.classList.add('dark-theme');
      this.htmlElement.classList.remove('light-theme');
    } else {
      this.htmlElement.classList.remove('dark-theme');
      this.htmlElement.classList.add('light-theme');
    }
  }
}
