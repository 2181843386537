import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PerfilUsuarioParametrosModel } from "../models/perfil-usuario.parametros.model";
import { BaseService, ResponseService } from "./base.service";
import { PerfilUsuarioModel } from "../models/perfil-usuario.model";
import { PermissaoTelaParametrosModel } from "../models/permissao-tela-parametros.model";
import { PermissaoTelaModel } from "../models/permissao-tela.model";

@Injectable({
  providedIn: "root",
})
export class PerfilUsuarioService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  gravar(
    perfil: PerfilUsuarioModel
  ): Observable<ResponseService<PerfilUsuarioModel>> {
    const url = `${this.baseUrl}/PerfilUsuario`;
    return this.http.post(url, perfil) as Observable<
      ResponseService<PerfilUsuarioModel>
    >;
  }

  excluir(id: number): Observable<ResponseService<PerfilUsuarioModel>> {
    let params = new HttpParams();

    params = params.append("id", id);
    const url = `${this.baseUrl}/PerfilUsuario`;
    return this.http.delete(url, { params }) as Observable<
      ResponseService<PerfilUsuarioModel>
    >;
  }

  buscarPerfilUsuario(
    parametros: PerfilUsuarioParametrosModel
  ): Observable<ResponseService<PerfilUsuarioModel[]>> {
    let params = new HttpParams();

    Object.keys(parametros).forEach((id) => {
      if (!!(parametros as any)[id] || (parametros as any)[id] === 0) {
        params = params.append(id, (parametros as any)[id]);
      }
    });

    const url = `${this.baseUrl}/PerfilUsuario`;
    return this.http.get(url, { params }) as Observable<
      ResponseService<PerfilUsuarioModel[]>
    >;
  }

  buscaFluxoAcesso(
    parametros: PermissaoTelaParametrosModel
  ): Observable<ResponseService<PermissaoTelaModel[]>> {
    let params = new HttpParams();

    Object.keys(parametros).forEach((id) => {
      if (!!(parametros as any)[id] || (parametros as any)[id] === 0) {
        params = params.append(id, (parametros as any)[id]);
      }
    });

    const url = `${this.baseUrl}/PerfilUsuario/FluxoPermissoes`;
    return this.http.get(url, { params }) as Observable<
      ResponseService<PermissaoTelaModel[]>
    >;
  }
}
