import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavService } from '../../../../services/nav.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '@src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    NgScrollbarModule,
    TablerIconsModule,
    MaterialModule,
    RouterModule,
    CommonModule
],
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  navopt = this.navService.showClass;
  nomeUsuario: string = '';

  constructor(
    public navService: NavService,
    private router: Router,
    private auth: AuthService
  ) {}

  logout() {
    try {
      this.auth.logout();
    } finally {
      this.router.navigate(['authentication/login']);
    }
  }
  ngOnInit(): void {
    this.auth.getNomeUsuario().subscribe((dados) => {
      this.nomeUsuario = dados.nome!
      .split(' ')
      .splice(0, 2)
      .join(' ');
    });
  }
}
