import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, ResponseService } from './base.service';
import { FazendaFuncionarioModel } from '../models/fazenda-funcionario.model';
import { FazendaFuncionarioParametrosModel } from '../models/fazenda-funcionario.parametros.model';

@Injectable({
  providedIn: 'root',
})
export class FazendaFuncionarioService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  alterar(
    fazenda: FazendaFuncionarioModel
  ): Observable<ResponseService<FazendaFuncionarioModel>> {
    const url = `${this.baseUrl}/FazendaFuncionario`;
    return this.http.put(url, fazenda) as Observable<
      ResponseService<FazendaFuncionarioModel>
    >;
  }

  gravar(
    data: FazendaFuncionarioModel
  ): Observable<ResponseService<FazendaFuncionarioModel>> {
    const url = `${this.baseUrl}/FazendaFuncionario`;
    return this.http.post(url, data) as Observable<
      ResponseService<FazendaFuncionarioModel>
    >;
  }

  excluir(id: number): Observable<ResponseService<FazendaFuncionarioModel>> {
    let params = new HttpParams();

    params = params.append('id', id);
    const url = `${this.baseUrl}/FazendaFuncionario`;
    return this.http.delete(url, { params }) as Observable<
      ResponseService<FazendaFuncionarioModel>
    >;
  }

  buscarFazendaFuncionario(
    parametros: FazendaFuncionarioParametrosModel
  ): Observable<ResponseService<FazendaFuncionarioModel[]>> {
    let params = new HttpParams();

    Object.keys(parametros).forEach((id) => {
      if (!!(parametros as any)[id] || (parametros as any)[id] === 0) {
        params = params.append(id, (parametros as any)[id] as number);
      }
    });

    const url = `${this.baseUrl}/FazendaFuncionario`;
    return this.http.get(url, { params }) as Observable<
      ResponseService<FazendaFuncionarioModel[]>
    >;
  }
}
