import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private isLoading = false;

  startLoading(): void {
    setTimeout(() => {
      this.isLoading = true;
    }, 0);
  }

  stopLoading(): void {
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  isLoadingActive(): boolean {
    return this.isLoading;
  }
}
