import { Injectable, inject } from '@angular/core';
import {
  CanMatch,
  CanMatchFn,
  Route,
  Router,
  UrlSegment,
} from '@angular/router';
import { map, take } from 'rxjs';
import { CustomActivatedRoute } from '../custom-activated-route';
import { AuthService } from '../services/auth.service';
import { PerfilUsuarioService } from '../services/perfil-usuario.service';
import { Store } from '@ngrx/store';
import * as AuthActions from '../store/auth/auth.actions';

// @Injectable({
//   providedIn: 'root'
// })

// export class AuthGuard implements CanActivate {
//   constructor(
//     private auth: AuthService,
//      private router: Router
//   ){}

//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//     ):
//       | Observable<boolean | UrlTree>
//       | Promise<boolean | UrlTree>
//       | boolean
//       | UrlTree {
//         //se o usuário estiver sem sessão,
//         //o enviamos para a tela de login
//         if(this.auth.)
//       }
//     }
//   }
// }

export const somenteAgroControle: string[] = [
  'cliente',
  // 'regional',
  // 'fazenda',
  'parametrospulverizacao',
   'parametrosherbicida',
   'parametrosadubacao',
   'parametrostratos',
  'parametrosproduto',
  'parametrosfrota',
  // 'produto',
  //'usuario',
  //'opcoes',
  'campo',
  'grupoCargo',
  'turno',
  'fretefabrica'
];

export const authGuard = (customRoute: CustomActivatedRoute) => {
  const authService = inject(AuthService);
  const perfilUsuarioService = inject(PerfilUsuarioService);
  const store = inject(Store);
  const router = inject(Router);

  function getPreviousUrl(): string {
    const history = router.config;
    const currentUrl = router.url;

    for (let i = history.length - 1; i > 0; i--) {
      if (currentUrl.includes(history[i].path!)) {
        return history[i - 1].path!;
      }
    }

    return '/';
  }

  // const requiredPermissions = router.data?.requiredPermissions as string[];
  return authService.getAccessToken().pipe(
    take(1),
    map((accessToken: string) => {
      if (accessToken) {
        authService.getDataToken().subscribe({
          next: (data) => {
            perfilUsuarioService
            .buscaFluxoAcesso({
              usuarioId: data.UsuarioId,
            })
            .subscribe({
              next: (ret) => {
                authService.saveFluxoAcesso(ret.data!)
                const permissoes = ret.data?.filter((a) =>
                customRoute.data.requiredPermissions.includes(
                  a.nomeModulo.toLowerCase().split(' ').join('-')
                  )
                  );
                authService.getDataToken().subscribe({
                  next: (data) => {
                  const isAgroControle =
                    data.ClienteNome === "AgroControle";
                  if (!isAgroControle) {
                    if (
                      somenteAgroControle.some((item) =>
                        customRoute.data.requiredPermissions.includes(item)
                      )
                    ) {
                      return false;
                    }
                  }
                  if (permissoes && permissoes?.length > 0) {
                    if (permissoes.find((a) => a.nomePermissao != 'Leitura')) {
                      store.dispatch(
                        AuthActions.updatePermissao({
                          permissao:
                            permissoes.find((a) => a.nomePermissao != 'Leitura')
                              ?.nomePermissao || '',
                        })
                      );
                      return true;
                    } else {
                      if (
                        permissoes.find((a) => a.nomePermissao == 'Leitura') &&
                        router.url.includes('cadastro')
                      ) {
                        router.navigate([getPreviousUrl()]);
                        return false;
                      }
                      store.dispatch(
                        AuthActions.updatePermissao({
                          permissao: 'Leitura',
                        })
                      );
                      return true;
                    }
                  }
                  router.navigate([getPreviousUrl()]);
                  return false;
                }});
              },
            });
          }
        });
        return true;
      } else {
        // O usuário não está autenticado, redirecionar para a página de login
        router.navigate(['/authentication/login']);
        return false;
      }
    })
  );
};
