<mat-card class="cardWithShadow">
  <mat-card-content>
    <div class="profile-container">
      <h2>Perfil do Funcionário</h2>
      <div class="profile-info">
        <p><strong>Nome do Funcionário:</strong> {{ funcionarioNome }}</p>
        <p><strong>Cargo:</strong> {{ cargo }}</p>
        <p><strong>Nome da Empresa:</strong>{{ cliente }}</p>
        <p *ngIf="fazendas && fazendas.length > 0">
          <strong>Fazendas:</strong>
          <ng-container *ngFor="let item of fazendas; let last = last">
            <span>{{ item.nome }}</span
            >{{ !last ? ", " : "" }}
          </ng-container>
        </p>
        <p *ngIf="!fazendas || fazendas.length === 0">
          <em>Nenhuma fazenda disponível.</em>
        </p>
      </div>
      <button class="change-password-btn" (click)="changePassword()">
        Alterar Senha
      </button>
    </div>
  </mat-card-content>
</mat-card>
