import { createAction, props } from '@ngrx/store';
import { AuthState } from '../app.state';

export const login = createAction(
  '[Auth] Login',
  props<{ username: string; password: string }>()
);
export const logout = createAction('[Auth] Logout');
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<AuthState>()
);
export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);
export const refreshToken = createAction(
  '[Auth] Refresh Token',
  props<{ access_token: string }>()
);
export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ access_token: string }>()
);
export const refreshTokenFailure = createAction(
  '[Auth] Refresh Token Failure',
  props<{ error: any }>()
);

export const updatePermissao = createAction(
  '[Auth] Update Permissao',
  props<{ permissao: string }>()
);
